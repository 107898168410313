@media (max-width: $screen-lg) {
    // lg-md view

    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: initial !important;
    }

    .wrapper {
    	min-width: 690px;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 89.84375%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {
        padding: 0 0 $footer-size-xs;
    }

    .content.rose-background {
    	position: relative;
    	&:before, &:after {
    		display: none;
    	}
    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        margin: -$footer-size-xs auto 0;
        height: $footer-size-xs;
    }

    .page-heading {
        .page-heading-holder {
            h1, .h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                line-height: 150%;
            }
        }
    }

    h1, .h1 {
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 150%;
    }
}

@media (max-width: $screen-md) {
    // md-view

}
