@media (max-width: $screen-sm) {
    // sm-view
    $menu-width: 320px;
    .mobile-menu {
    	.mobile-menu-holder {
            .menu-body {
                width: $menu-width;
                &.animated {
    				transform: translateX(-$menu-width);
    			}
                .menu-heading {
                    .phone {
                        margin-top: -7px;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
