.header-holder {
    display: inline-block;
    width: 100%;
    .header-note-slider {
        display: inline-block;
        width: 100%;
        height: 42px;
        overflow: hidden;
        .header-note-slider-holder {
            .header-note {
                display: inline-block;
                width: 100%;
                background: $bg-secondary;
                &.green {
                    background: $brand-primary;
                    color: white;
                }
                &.pink {
                    background: #FF9197;
                    color: white;
                }
                .header-note-holder {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 44px;
                    .icon {
                        display: inline-block;
                        float: left;
                        margin-right: 20px;
                        svg {

                        }
                    }

                    .text {
                        display: inline-block;
                        float: left;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        text-transform: uppercase;
                    }
                }
        	}
        }
    }


	.header-top {
        display: inline-block;
        width: 100%;
        background: $bg-secondary;
        .header-top-holder {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 40px;
            .top-menu {
                display: inline-block;
                float: left;
                nav {

                    ul {
                        margin: 0;
                        padding: 0;
                        li {
                            display: inline-block;
                            float: left;
                            margin-right: 20px;
                            a {
                                display: inline-block;
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 150%;
                                color: $gray;
                                &:hover {
                                    color: $brand-primary;
                                }
                            }
                        }
                    }
                }
            }

            .socials {
                display: inline-block;
                float: right;
                .text {
                    display: inline-block;
                    float: left;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    color: $gray;
                    margin: 1px 15px 0 0px;
                }

                .socials-list {
                    display: inline-block;
                    float: left;
                    ul {
                        display: inline-block;
                        margin: 0;
                        padding: 0;
                        li {
                            display: inline-block;
                            float: left;
                            margin-left: 10px;
                            &:first-of-type {
                                margin-left: 0;
                            }
                            a {
                                display: inline-block;
                                width: 24px;
                                height: 24px;
                                background: $brand-primary;
                                &:hover {
                                    svg {
                                        opacity: 0.5;
                                        transition: all 300ms;
                                    }
                                }
                                svg {
                                    width: 100%;
                                    height: 100%;
                                    transition: all 300ms;
                                }
                            }
                        }
                    }
                }
            }
        }
	}

	.header-main {
        display: inline-block;
        width: 100%;
        .header-main-holder {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 120px;
            .phone {
                display: inline-block;
                float: left;
                margin: 0 0 6px;
                a {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 130%;
                    color: $gray-base;
                    &:hover {
                        color: $brand-primary;
                    }
                }
            }

            .fast-order {
                display: inline-block;
                float: left;
                a {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 130%;
                    border-radius: 70px;
                    border: 1px solid rgba(0, 121, 64, 0.2);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 130%;
                    text-transform: uppercase;
                    padding: 14px 0 15px;
                    width: 150px;
                    text-align: center;
                    &:hover {
                        border: 1px solid $brand-primary;
                    }
                }
            }

            .logo {
                display: inline-block;
                float: left;
                width: 38%;
                text-align: center;
                box-sizing: border-box;
                padding: 0 24px 0 0;
                a {
                    display: inline-block;
                    svg {

                    }
                }
            }

            .personal {
                display: inline-block;
                float: left;
                a {
                    font-style: normal;
                    font-weight: 300;
                    font-size: 14px;
                    line-height: 150%;
                    color: $gray-base;
                    &:hover {
                        color: $brand-primary;
                    }
                    .icon {
                        display: inline-block;
                        float: left;
                        margin-right: 12px;
                        svg {

                        }
                    }

                    .text {
                        display: inline-block;
                        float: left;
                    }
                }
            }

            .cart {
                display: inline-block;
                float: left;
                margin: 0 7px 9px 0px;
                a {
                    color: $gray-base;
                    &:hover {
                        color: $brand-primary;
                    }
                    .icon {
                        display: inline-block;
                        float: left;
                        position: relative;
                        padding: 16px 22px 0px 0;
                        svg {

                        }

                        .items-in-cart {
                            position: absolute;
                            right: 10px;
                            top: 10px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 16px;
                            color: white;
                            background: $brand-primary;
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }

                    .text {
                        display: inline-block;
                        float: left;
                        .items-in-cart {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            color: $gray;
                        }

                        .cart-price {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 150%;

                        }
                    }
                }
            }
        }
	}

	.catalog-menu {
        display: inline-block;
        width: 100%;
        border-top: 1px solid #DFE5EA;
        border-bottom: 1px solid #DFE5EA;
        .catalog-menu-holder {
            display: inline-block;
            width: 100%;
            height: 60px;
            nav {
                height: 100%;
                >ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    width: 96%;
                    height: 100%;
                    >li {
                        display: inline-flex;
                        height: 100%;
                        align-items: center;
                        position: relative;
                        &:before {
                            content: "";
                            display: inline-block;
                            width: calc(100% + 40px);
                            height: 0;
                            border-top: 2px solid #00914C;
                            position: absolute;
                            left: -20px;
                            top: -2px;
                            opacity: 0;
                            transition: all 300ms;
                        }
                        &:hover {
                            &:before {
                                opacity: 1;
                                transition: all 300ms;
                            }
                            .sub-menu {
                                z-index: 1001;
                                opacity: 1;
                                max-height: 1000px;
                                overflow: visible;
                                transition: all 500ms;
                            }
                        }
                        >a {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 130%;
                            text-transform: uppercase;
                            color: $gray-base;
                            &:hover {
                                color: $brand-primary;
                            }
                        }
                        .sub-menu {
                            position: absolute;
                            left: -20px;
                            top: calc(100% + 1px);
                            z-index: -1;
                            opacity: 0;
                            max-height: 0;
                            overflow: hidden;
                            transition: all 500ms;
                            .sub-menu-container {
                                display: inline-block;
                                width: 240px;
                                background: #FFFFFF;
                                box-shadow: 0px 4px 5px rgba(150, 163, 173, 0.15);
                                &.col-2 {
                                    width: 460px;
                                }
                                &.col-3 {
                                    width: 680px;
                                }
                                .sub-menu-container-holder {
                                    display: inline-block;
                                    width: 100%;
                                    box-sizing: border-box;
                                    padding: 16px 20px 12px;
                                    .rose-list {
                                        display: inline-block;
                                        width: calc(100% + 20px);
                                        margin-left: -10px;
                                    	.rose-col {
                                            display: inline-block;
                                            width: 33.333%;
                                            float: left;
                                            box-sizing: border-box;
                                            padding: 0 10px;
                                    		.heading {
                                                display: inline-block;
                                                width: 100%;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 130%;
                                                margin-bottom: 19px;
                                    		}

                                            >ul {
                                                padding: 0;
                                                margin: 0;
                                                list-style: none;
                                                >li {
                                                    padding-bottom: 15px;
                                                    margin-bottom: 18px;
                                                    position: relative;
                                                    &:after {
                                                        content: "";
                                                        display: inline-block;
                                                        width: 100%;
                                                        height: 0;
                                                        border-bottom: 1px solid #DFE5EA;
                                                        position: absolute;
                                                        left: 0;
                                                        bottom: 0;
                                                    }
                                                    &:last-of-type {
                                                        margin: 0;
                                                        padding: 0;
                                                        &:after {
                                                            display: none;
                                                        }
                                                    }
                                                    a {
                                                        font-style: normal;
                                                        font-weight: 300;
                                                        font-size: 16px;
                                                        line-height: 150%;
                                                        color: $gray-base;
                                                        &:hover {
                                                            color: $brand-primary;
                                                        }
                                                    }
                                                }
                                            }
                                    	}
                                    }
                                    >ul {
                                        padding: 0;
                                        margin: 0;
                                        list-style: none;
                                        >li {
                                            padding-bottom: 12px;
                                            margin-bottom: 18px;
                                            position: relative;
                                            &:after {
                                                content: "";
                                                display: inline-block;
                                                width: 100%;
                                                height: 0;
                                                border-bottom: 1px solid #DFE5EA;
                                                position: absolute;
                                                left: 0;
                                                bottom: 0;
                                            }
                                            &:last-of-type {
                                                margin: 0;
                                                padding: 0;
                                                &:after {
                                                    display: none;
                                                }
                                            }
                                            a {
                                                font-style: normal;
                                                font-weight: 300;
                                                font-size: 16px;
                                                line-height: 150%;
                                                color: $gray-base;
                                                &:hover {
                                                    color: $brand-primary;
                                                }
                                            }
                                        }
                                    }
                                    >ul.two {
                                        width: calc(100% + 20px);
                                        margin-left: -10px;
                                        >li {
                                            display: inline-block;
                                            width: 50%;
                                            float: left;
                                            box-sizing: border-box;
                                            padding: 0 10px 14px;
                                            &:after {
                                                box-sizing: border-box;
                                                width: calc(100% - 20px);
                                                left: 10px;
                                            }
                                            &:nth-last-child(-n+2) {
                                                margin: 0;
                                                padding: 0 10px 0;
                                                &:after {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	}
}

@import "../media/tablet/base/header";
@import "../media/mobile/base/header";
