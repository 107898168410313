.fancybox-container.custom-fancybox {
    .fancybox-content {
        padding: 30px;
        overflow: initial;
        max-width: 400px;
        min-height: 320px;
        box-sizing: border-box;
        border-radius: 15px;
        &.wide {
            max-width: 540px;
        }
    }
    button.fancybox-button.fancybox-close-small {
        display: none;
    }
}

.fancybox-slide--html {
    padding: 0;
}

.popup {
    position: relative;
    .custom-close {
        position: absolute;
        right: 15px;
        top: 15px;
        a {
            display: inline-block;
            text-decoration: none;
            svg {
                width: 24px;
                height: 24px;
                transition: all 300ms;
                color: #959EA8;
            }
            &:hover {
                svg {
                    color: $brand-primary;
                    transition: all 300ms;
                }
            }
        }
    }
}

.general-popup-container {
    display: inline-block;
    width: 100%;
    .general-popup-container-holder {
        display: inline-block;
        width: 100%;

        >.icon {
            display: inline-block;
            width: 100%;
            text-align: center;
            margin: 10px 0 20px;
            svg {
                display: inline-block;
            }
        }

        >.heading {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-family: $accent_font;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 150%;
            margin: 0 0 30px;
        }

        >.note {
            display: inline-block;
            width: 100%;
            text-align: center;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 150%;
            margin: 0 0 20px;
        }

        .item-description {
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
        	.image {
                display: inline-block;
                float: left;
                width: 95px;
                height: 113px;
        		a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 7px;
                    border: 1px solid #DFE5EA;
                    &:hover {
                        img {
                            opacity: 0.68;
                            transition: all 300ms;
                        }
                    }
        			img {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        transition: all 300ms;
        			}
        		}
        	}

        	.text {
                display: inline-block;
                float: left;
                width: calc(100% - 95px);
                box-sizing: border-box;
                padding-left: 20px;
        		.name {
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 10px;
        			a {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
        			}
        		}

        		.params {
                    display: inline-block;
                    width: 100%;
        			.param {
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
        			}
        		}
        	}
        }

        .double-action {
            display: flex;
            width: calc(100% + 20px);
            margin-left: -10px;
        	.btn {
                margin: 0 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                width: 50%;
                float: left;
                padding: 15px 0;
                text-align: center;
                text-transform: uppercase;
                border-radius: 10px;
        	}
        }

        .single-action {
            display: inline-block;
            width: 100%;
            .btn {
                display: inline-block;
                width: 100%;
                padding: 15px 0;
                text-align: center;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-transform: uppercase;
                border-radius: 10px;
            }
        }

        form {
            .action {
                .submit {
                    width: 100%;
                    .btn {
                        width: 100%;
                        text-align: center;
                        padding: 15px 0;
                    }
                }

                .agree-col {
                    width: 100%;
                    padding: 10px 0 0;
                    text-align: center;
                    font-style: normal;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 130%;
                    color: $gray;
                    a {
                        color: $gray-base;
                        &:hover {
                            color: $brand-primary;
                        }
                    }
                    .restore-password {
                        padding: 10px 0 0;
                        a {
                            color: $brand-primary;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 16px;
                            line-height: 150%;
                            text-decoration-line: underline;
                        }
                    }
                }
            }
        }

        .auth-reg {
            display: inline-block;
            width: 100%;
            .heading {
                margin: 32px 0 33px;
                .heading-holder {
                    width: calc(100% + 30px);
                    margin-left: -15px;
                    display: flex;
                    justify-content: center;
                    a {
                        margin: 0 15px;
                        font-family: $accent_font;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 18px;
                        line-height: 150%;
                        color: $gray;
                        position: relative;
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 0;
                            border-bottom: 3px solid $brand-primary;
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            opacity: 0;
                            transition: all 300ms;
                        }
                        &.current {
                            color: $gray-base;
                            &:after {
                                opacity: 1;
                                transition: all 300ms;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/popups/custom_fancybox";
@import "../../media/mobile/includes/popups/custom_fancybox";
