@import "../mixins";

$menu-width: 400px;

/*Mobile menu
-----------------------------------------------------------------------------*/
.mobile-menu {
	.mobile-menu-holder {
		.menu-background {
			position: fixed;
			z-index: 1201;
			top: 0;
			left: 0;
			background: $gray-base;
			opacity: 0.64;
			width: 100%;
			height: 100%;
			@include transition-easy-in-out(250ms);
			&.animated {
				opacity: 0;
				@include transition-easy-in-out(250ms);
			}
			&.closed {
				display: none;
			}
		}
		.menu-body {
			position: fixed;
			z-index: 1202;
			top: 0;
			left: 0;
			display: inline-block;
			float: left;
			width: $menu-width;
			height: 100%;
			box-sizing: border-box;
			padding: 0;
			background: white;
			opacity: 1;
			transform: translateX(0);
			overflow-y: auto;
			@include transition-easy-in-out(350ms);
			&.animated {
				transform: translateX(-$menu-width);
				@include transition-easy-in-out(350ms);
			}
			&.closed {
				display: none;
			}
            &.mobile-offset {

            }
			.menu-heading {
				display: inline-block;
				width: 100%;
				height: 54px;
			    padding: 16px 40px 0 16px;
				box-sizing: border-box;
				position: relative;
				.reset-filter {
					display: inline-block;
					float: left;
					a {
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 150%;
						color: $brand-primary;
						text-decoration: underline;
					}
				}
				.phone {
					display: inline-block;
					float: left;
					margin-right: 30px;
					a {
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 130%;
						color: $gray-base
					}
				}
				.order {
					display: inline-block;
					float: left;
					a {
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 130%;
						text-decoration-line: underline;
						color: $brand-primary;
					}
				}
				a.close {
	                position: absolute;
				    right: 15px;
				    top: 15px;
	                svg {

	                }
	            }
			}
			.main-body {
				display: block;
				overflow-y: hidden;
			    overflow-x: hidden;
			    padding-top: 0;
				height: auto;
			}
            /*naib body contents*/
			.mobile-main-menu-nav {

				ul {
					padding: 0;
					margin: 0;
					list-style: none;
					li {
						position: relative;
						border-bottom: 1px solid #DFE5EA;
						>a {
							height: 49px;
							background: #F9F9F9;
							display: flex;
							align-items: center;
							font-style: normal;
							font-weight: 600;
							font-size: 13px;
							line-height: 130%;
							text-transform: uppercase;
							color: $gray-base;
						    box-sizing: border-box;
						    padding: 0 0 0 15px;
						}
					}

					li.have-sub-menu {

						>a {
							position: relative;
							&:after {
								content: "";
								display: inline-block;
								width: 10px;
								height: 10px;
								background-image: url('../img/have-sub-menu.svg');
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;
								background-clip: border-box;
								position: absolute;
								right: 18px;
								top: 50%;
								transform: translateY(-50%);
							}
						}

						.sub-menu {
							display: inline-block;
							width: 100%;
							height: 100%;
							position: fixed;
							left: 0;
							top: 0;
							background: white;
							z-index: 1;
							transform: translateX(-100%);
							transition: all 300ms;
							&.open {
								transform: translateX(0);
								transition: all 300ms;
							}
							.return {
								display: inline-block;
								width: 100%;
								border-top: 1px solid #DFE5EA;
								border-bottom: 1px solid #DFE5EA;
								>a {
									height: 49px;
									background: #FFFFFF;
									display: flex;
									align-items: center;
									font-style: normal;
									font-weight: 600;
									font-size: 13px;
									line-height: 130%;
									text-transform: uppercase;
									color: $gray-base;
								    box-sizing: border-box;
									position: relative;
								    padding: 0 0 0 38px;
									&:after {
										content: "";
										display: inline-block;
										width: 13px;
										height: 8px;
										background-image: url('../img/return-arrow.svg');
										background-position: center;
										background-repeat: no-repeat;
										background-size: contain;
										background-clip: border-box;
										position: absolute;
										left: 15px;
										top: 50%;
										transform: translateY(-50%);
									}
								}
							}


						}
					}

					li.add {

						>a {
							font-style: normal;
							font-weight: 300;
							font-size: 16px;
							line-height: 150%;
							text-transform: none;
						}
					}
				}
			}

			.socials {
				display: inline-block;
				width: 100%;
				box-sizing: border-box;
				padding: 19px 0 0 16px;
				.socials-links {
					display: inline-block;
					width: 100%;
					.heading {
						display: inline-block;
						float: left;
						font-style: normal;
						font-weight: 300;
						font-size: 14px;
						line-height: 150%;
						margin-right: 9px;
						padding-top: 5px;
					}

					ul {
						display: inline-block;
						float: left;
						padding: 0;
						margin: 0;
						list-style: none;
						li {
							display: inline-block;
							float: left;
							margin: 0 5px;
							a {
								display: inline-block;
								svg {}
							}
						}
					}
				}
			}

			.mobile-filter {
				display: inline-block;
				width: 100%;
				margin-top: 1px;
		        height: calc(100vh - 55px);
				box-sizing: border-box;
				padding-bottom: 53px;
				position: relative;
				form {
					display: inline-block;
					width: 100%;
					height: 100%;
					overflow-y: scroll;
					.filter-block {
						display: inline-block;
						width: 100%;
						overflow: hidden;
						margin: 0 0 -1px;
					    float: left;
						border-top: 1px solid #DFE5EA;
						border-bottom: 1px solid #DFE5EA;
						transition: all 300ms;
						&.open {
							transition: all 300ms;
							.filter-block-holder {
								.filter-block-heading {
									a {
										&:after {
											transform: translateY(-50%) rotate(180deg);
											transition: all 300ms;
										}
									}
								}
							}
						}
						.filter-block-holder {
							display: inline-block;
							width: 100%;
							.filter-block-heading {
								display: inline-block;
								width: 100%;
								a {
									display: flex;
									align-items: center;
									width: 100%;
									font-style: normal;
									font-weight: 600;
									font-size: 16px;
									line-height: 130%;
									color: $gray-base;
									position: relative;
									height: 50px;
									box-sizing: border-box;
									padding: 0 33px 0 15px;
									&:after {
										content: "";
										width: 12px;
										height: 9px;
										background-image: url('../img/mobile-filter-open.svg');
										background-position: center;
										background-repeat: no-repeat;
										background-size: contain;
										background-clip: border-box;
										position: absolute;
									    right: 17px;
										top: 50%;
										transform: translateY(-50%);
										transition: all 300ms;
									}
								}
							}

							.filter-block-content {
								display: inline-block;
								width: 100%;
								.filter-block-content-holder {
									display: inline-block;
									width: 100%;
									box-sizing: border-box;
    								padding: 6px 0 2px;
									ul.check-list {
										display: inline-block;
										width: 100%;
										padding: 0;
										margin: 0;
										list-style: none;
										li {
											display: inline-block;
											width: 100%;
											box-sizing: border-box;
											padding: 0 0 0 15px;
    										margin: 0 0 -3px;
											.form-field {

												.checkbox {

													input {

													}

													.checkmark {
														border: 1px solid #DFE5EA;
													}

													.name {
														font-style: normal;
														font-weight: 300;
														font-size: 16px;
														line-height: 150%;
													    margin-left: 8px;
														margin-bottom: 0;
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.action {
						display: inline-block;
						width: 100%;
						position: absolute;
						left: 0;
						bottom: 0;
						.btn {
							display: inline-block;
							width: 100%;
							padding: 15px 0;
							text-align: center;
							font-style: normal;
							font-weight: 600;
							font-size: 14px;
							line-height: 21px;
							text-transform: uppercase;
						}
					}
				}
			}
		}
	}
}

@import "../media/mobile/includes/mobile_menu";
@import "../media/tablet/includes/mobile_menu";
