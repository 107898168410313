.slick-prev, .slick-next {
    display: none;
    &.slick-arrow {
        display: flex;
    }
}

.slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 21px));
    z-index: 1001;
    font-size: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    color: $brand-primary;
    transition: all 300ms ease;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid $brand-primary;
    border-radius: 50%;
    filter: drop-shadow(0px 15px 20px rgba(220, 220, 220, 0.3));
    text-align: center;
    &.smaller {
        width: 36px;
        height: 36px;
        line-height: 36px;
    }
    &.slick-hidden {
        opacity: 0;
        z-index: -1;
    }
    &.slick-disabled {
        cursor: default;
        border: 1px solid rgba(84, 91, 99, 0.1);
        color: $gray;
        svg {

        }
        &:hover {
            background: #FFFFFF;
            color: $gray;
        }
    }
    &.slick-prev, &.prev {
        left: -1px;
    }
    &.slick-next, &.next {
        right: -1px;
        svg {
            transform: rotate(180deg);
        }
    }
    &:focus {
        outline: none;
    }
    &:hover {
        background: $brand-primary;
        color: white;
        transition: all 300ms ease;
    }
    svg {
        width: 16px;
        height: 8px;
        vertical-align: middle;
    }
}

ul.slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 0;
    margin: 0;
    li {
        display: inline-block;
        float: left;
        margin: 0 4px;
        &.slick-active {
            button {
                cursor: default;
                background-color: $gray-base;
                opacity: 1;
                &:hover {
                    opacity: 1;
                }
            }
        }
        button {
            font-size: 0;
            position: relative;
            width: 8px;
            height: 8px;
            background-color: #DFE5EA;
            border: none;
            box-sizing: border-box;
            border-radius: 50%;
            transition: all 300ms ease;
            cursor: pointer;
            padding: 0;
            opacity: 0.6;
            &:focus {
                outline: none;
            }
            &:hover {
                background-color: #DFE5EA;
                opacity: 1;
                transition: all 300ms ease;
            }
        }
    }
}

.slick-slider {
    width: calc(100% + 30px);
    margin-left: -15px;
    position: relative;
    .slick-list {
        height: 100%;
        overflow: hidden;
        &:after{
          clear: both;
          content: "";
          display: block;
        }
        .slick-track {
            height: 100%;
            .slick-slide {
                display: inline-block;
                float: left;
                height: 100%;
                box-sizing: border-box;
                padding: 0 15px;
                >div {
                    height: 100%;
                }
            }
        }
    }
}
.slick-slider.full {
    width: 100%;
    margin: 0;
    .slick-list {
        .slick-track {
            .slick-slide {
                padding: 0;
            }
        }
    }
}
.slick-slider.auto-height {
    .slick-list {
        height: auto;
        .slick-track {
            height: auto;
            .slick-slide {
                height: auto;
                padding: 0;
                >div {
                    height: auto;
                }
            }
        }
    }
}
.slick-slider.disabled-slider {
    .slick-list {
        .slick-track {
            width: auto !important;
            transform: translate3d(0, 0px, 0px) !important;
        }
    }
}
.slick-slider.outer {
    .slick-list {
        overflow: visible;
    }
}

.catalog-list.reverse-mobile-slider {
    position: relative;
    padding: 0 0 34px;
}
.slick-slider.catalog-list-holder.reverse-slider-container {
    width: 100%;
    .slick-list {
        padding: 4px 2px;
        box-sizing: border-box;
        width: calc(100% + 4px);
        margin-left: -2px;
    }
}

.common-slider {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 0;
    .prev, .next {
        display: none;
        .slick-arrow {
            display: flex;
        }
    }
}

@import "../media/tablet/includes/slick";
@import "../media/mobile/includes/slick";
