@media (max-width: $screen-sm) {
    // sm-view
    .catalog-list.reverse-mobile-slider {
        position: relative;
        padding: 0 0 22px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
