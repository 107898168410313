@media (max-width: $screen-sm) {
    // sm-view
    .header-holder {

        .header-note-slider {
            height: 44px;
            .header-note-slider-holder {
                .header-note {
                    .header-note-holder {
                        box-sizing: border-box;
                        padding: 0 0 0 13px;
                        .icon {
                            margin-right: 10px;
                            svg {
                                width: 30px;
                                height: 30px;
                            }
                        }

                        .text {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }

        .header-main {
            .header-main-holder {
                .mobile-menu {
                    margin-right: 0;
                    a {

                    }
                }

                .logo {
                    width: 53%;
                    margin-right: 3px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
