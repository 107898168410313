@media (max-width: $screen-lg) {
    // lg-md view
    .header-holder {
        border: 1px solid #DFE5EA;
        .header-main {
            .header-main-holder {
                height: 59px;
                .mobile-menu {
                    margin-right: 17px;
                    a {
                        display: inline-block;
                        svg {

                        }
                    }
                }

                .phone {
                    margin: 0;
                    margin-right: 29px;
                }

                .fast-order {
                    a {
                        border: none;
                        padding: 0;
                        width: auto;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 130%;
                        text-transform: none;
                        text-decoration-line: underline;
                    }
                }

                .logo {
                    width: 26.1%;
                    padding: 0;
                    margin-right: 20px;
                    a {
                        width: 100%;
                        svg {
                            max-width: 100%;
                        }
                    }
                }

                .personal {
                    a {
                        .icon {
                            margin: 0;
                        }

                        .text {

                        }
                    }
                }

                .cart {
                    margin: 0;
                    a {
                        .icon {
                            padding: 0 13px 0 0;
                            svg {

                            }

                            .items-in-cart {
                                right: 0px;
                                top: -6px;
                            }
                        }
                    }
                }
            }
        }

    }
}

@media (max-width: $screen-md) {
    // md-view
}
