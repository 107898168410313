form {

    .action {
        .agree-col {
            display: inline-block;
            width: calc(100% - 320px);
            float: left;
            box-sizing: border-box;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 130%;
            color: $gray-light;
            padding-left: 30px;
            padding-top: 5px;
            a {
                color: $gray-base;
                &:hover {
                    color: $brand-primary;
                }
            }
            .checkcontainer {
                .name {
                    a {
                        color: $brand-secondary;
                        text-decoration: none;
                        transition: all 300ms;
                        &:hover {
                            cursor: $brand-secondary-accent;
                            transition: all 300ms;
                        }
                    }
                }
            }
        }
        .submit {
            width: 320px;
            float: left;
            .btn {
                width: 100%;
                padding: 16px 0;
                text-align: center;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-transform: uppercase;
                border-radius: 10px;
            }
        }
    }

    .error-note {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        position: relative;
        padding-top: 10px;
        &:before {
            content: "";
            display: inline-block;
            width: 88px;
            height: 0;
            border-top: 2px solid #EB5757;
            position: absolute;
            left: 0;
            top: 0;
        }
        .note {
            margin-bottom: 10px;
            &.error {
                color: red;
            }
            &.warning {
                color: #FFD300;
            }
        }

    }

    .form-group {
        display: inline-block;
        width: calc(100% + 30px);
        margin-left: -15px;
    	.form-col {
            display: inline-block;
            float: left;
            width: 100%;
            box-sizing: border-box;
            padding: 0 15px;
            &.col-50 {
                width: 50%;
            }
            &.col-33 {
                width: calc(100%/3);
            }

    	}
    }

    .form-field {
        display: inline-block;
        width: 100%;
        margin-bottom: 16px;
        label {
            .name {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 10px;
            }
        }
        label.select {
            .name {

            }

            .selectric-selectric-select {
                .selectric {
                    background: #FFFFFF;
                    border: 1px solid #DFE5EA;
                    box-sizing: border-box;
                    border-radius: 10px;
                    height: 55px;
                    .label {
                        font-family: $main_font;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 150%;
                        color: $gray;
                        display: flex;
                        align-items: center;
                        margin: 0 45px 0 20px;
                        height: 100%;
                    }
                    .button {
                        height: 100%;
                        width: 43px;
                        &:after {
                            border: none;
                            background-image: url(../img/select-shevron.svg);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: auto;
                            background-clip: border-box;
                            width: 16px;
                            height: 12px;
                        }
                    }
                }

                .selectric-items {
                    top: calc(100% - 1px);
                    border: 1px solid #DFE5EA;
                    box-sizing: border-box;
                    box-shadow: 0px 4px 5px rgba(150, 163, 173, 0.15);
                    border-radius: 10px;
                    background: white;
                    min-width: 252px;
                    z-index: 2;
                    .selectric-scroll {
                        ul {
                            display: inline-block;
                            width: 100%;
                            padding: 18px 20px 15px;
                            li {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 150%;
                                padding-bottom: 15px;
                                margin-bottom: 18px;
                                border-bottom: 1px solid #DFE5EA;
                                &:hover {
                                    background: transparent;
                                    color: $brand-primary;
                                }
                                &:last-of-type {
                                    padding-bottom: 0;
                                    margin-bottom: 0;
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        label.input {
            display: inline-block;
            width: 100%;
            position: relative;
            &.addon {
                input {
                    padding: 0px 30px 0 14px;
                }
            }

            &.error {
                input {
                    border-color: red;
                    color: red;
                    @include placeholder {
                        color: red;
                    }
                    &:focus {
                        border-color: red;
                    }
                }
            }
            &.success {
                input {
                    border-color: green;
                    @include placeholder {

                    }
                    &:focus {
                        border-color: green;
                    }
                }
            }
            &.warning {
                input {
                    border-color: #FFD300;
                    @include placeholder {

                    }
                    &:focus {
                        border-color: yellow;
                    }
                }
            }

            .name {

            }

            input, textarea {
                display: inline-flex;
                float: left;
                width: 100%;
                height: 55px;
                align-items: center;
                padding: 0px 15px 0 15px;
                font-family: $main_font;
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 150%;
                background: #F7F7F7;
                border: 1px solid #DFE5EA;
                border-radius: 10px;
                box-sizing: border-box;
                transition: all 300ms;
                color: $gray-base;
                &:focus {
                    outline: none;
                    background: white;
                    border-color: $brand-primary;
                    transition: all 300ms;
                }
                @include placeholder {
                    color: $gray;
                }
            }
            textarea {
                padding: 14px 10px 0 15px;
                height: 120px;
            }

            .addon {
                position: absolute;
                bottom: 50%;
                transform: translateY(50%);
                right: 8px;
                button {
                    padding: 0;
                    margin: 0;
                    background: none;
                    outline: none;
                    border: none;
                    color: $gray-base;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
                }
                a {
                    display: inline-block;
                    color: $gray-base;
                    text-decoration: none;
                    transition: all 300ms;
                    .icon {
                        svg {

                        }
                    }
                    &:hover {
                        color: $brand-secondary;
                        transition: all 300ms;
                    }
                }
            }
        }
    }
}

// @import "../media/tablet/includes/forms";
// @import "../media/mobile/includes/forms";
