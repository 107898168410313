.footer-holder {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: #F7F7F7;
    .footer-main {
        display: inline-block;
        width: 100%;
    	.footer-main-holder {
            display: inline-block;
            width: 100%;
            padding: 50px 0 0;
    		.company {
                display: inline-block;
                float: left;
                width: 25%;
    			.company-holder {
                    display: inline-block;
                    width: 100%;
    				.logo {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 20px;
    					a {

    						svg {
    						}
    					}
    				}

    				.contacts {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 24px;
    					.contact-line {
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            margin-bottom: 5px;
    						a {
                                color: $gray-base;
                                &:hover {
                                    color: $brand-primary;
                                }
    						}
    					}
    				}

    				.copyright {
                        display: inline-block;
                        width: 100%;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 130%;
                        color: $gray;
                        a {
                            color: $gray;
                        }
    				}
    			}
    		}

    		.footer-menu {
                display: inline-block;
                float: left;
                width: 50%;
    			.footer-menu-holder {
                    display: flex;
                    justify-content: center;
                    box-sizing: border-box;
                    padding: 0 0 0 37px;
    				ul {
                        column-count: 2;
                        column-gap: 98px;
                        margin: 0;
                        padding: 0;
                        list-style: none;
    					li {
                            margin-bottom: 9px;
    						a {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 130%;
                                text-transform: uppercase;
                                color: black;
                                &:hover {
                                    color: $brand-primary;
                                }
    						}
    					}
    				}
    			}
    		}

    		.socials {
                display: inline-block;
                width: 25%;
                float: right;
    			.socials-holder {
                    display: inline-block;
                    width: 100%;
    				.paying-methods {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 18px;
    					.heading {
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            text-align: right;
                            margin-bottom: 10px;
    					}
    					ul {
                            display: inline-block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            list-style: none;
    						li {
                                display: inline-block;
                                float: right;
                                margin-left: 6px;
                                &:last-of-type {
                                    margin-left: 0;
                                }
    							a {
                                    display: inline-block;
                                    &:hover {
                                        opacity: 0.5;
                                    }
    								svg {

    								}
    							}
    						}
    					}
    				}

    				.socials-links {
                        display: inline-block;
                        width: 100%;
                        margin-bottom: 36px;
    					.heading {
                            display: inline-block;
                            width: 100%;
                            font-style: normal;
                            font-weight: 300;
                            font-size: 14px;
                            line-height: 150%;
                            text-align: right;
                            margin-bottom: 10px;
    					}

    					ul {
                            display: inline-block;
                            width: 100%;
                            margin: 0;
                            padding: 0;
                            list-style: none;
    						li {
                                display: inline-block;
                                float: right;
                                margin-left: 10px;
                                &:last-of-type {
                                    margin-left: 0;
                                }
    							a {
                                    display: inline-block;
                                    &:hover {
                                        opacity: 0.5;
                                    }
    								svg {

    									path {

    									}
    								}
    							}
    						}
    					}
    				}

    				.policy {
                        display: inline-block;
                        width: 100%;
                        text-align: right;
    					a {
                            font-style: normal;
                            font-weight: 300;
                            font-size: 12px;
                            line-height: 150%;
                            text-decoration-line: underline;
                            color: $gray;
                            text-decoration-color: currentColor;
                            &:hover {
                                color: $brand-primary;
                                text-decoration-color: transparent;
                            }
    					}
    				}
    			}
    		}
    	}
    }
}

@import "../media/tablet/base/footer";
@import "../media/mobile/base/footer";
