@media (max-width: $screen-lg) {
    // lg-md view
    .footer-holder {
        .footer-main {
            .footer-main-holder {
                padding: 30px 0 0;
                .company {
                    width: 38%;
                    .company-holder {
                        .logo {}

                        .contacts {
                            margin-bottom: 14px;
                            .contact-line {
                                a {}
                            }
                        }
                    }
                }

                .footer-menu {
                    display: none;
                }

                .socials {
                    width: 38%;
                    .socials-holder {
                        .paying-methods {}

                        .socials-links {
                            margin-bottom: 17px;
                        }

                        .policy {}
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
