@media (max-width: $screen-sm) {
    // sm-view
    .fancybox-container.custom-fancybox {
        .fancybox-content {
            padding: 40px 6.5vw 15px;
            min-height: 340px;
            &.wide {
                max-width: 320px;
            }
        }
    }

    .general-popup-container {
        .general-popup-container-holder {

            >.icon {
                margin: 0 0 19px;
                svg {

                }
            }

            >.heading {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 150%;
                margin: 0 0 20px;
            }

            .item-description {
                margin: 0 0 28px;
                .image {
                    width: 85px;
                    height: 101px;
                }

                .text {
                    width: calc(100% - 85px);
                    .name {
                        margin: 0 0 4px;
                        a {
                            font-size: 14px;
                        }
                    }

                    .params {
                        .param {
                            font-size: 14px;
                        }
                    }
                }
            }

            .double-action {
                width: 100%;
                margin-left: 0;
                flex-wrap: wrap;
            	.btn {
                    margin: 0 0 10px;
                    width: 100%;
                    float: left;
                    padding: 16px 0;
            	}
            }

            form {
                .action {
                    .submit {
                        .btn {

                        }
                    }

                    .agree-col {
                        a {

                        }
                        .restore-password {
                            padding: 10px 0 0;
                            a {
                                font-style: normal;
                                font-weight: 300;
                                font-size: 14px;
                                line-height: 150%;
                            }
                        }
                    }
                }
            }

            .auth-reg {
                .heading {
                    margin: 0 0 21px;
                    .heading-holder {
                        a {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 130%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
