@media (max-width: $screen-sm) {
    // sm-view
    .footer-holder {
        .footer-main {
            .footer-main-holder {
                .company {
                    width: 100%;
                    margin: 0 0 20px;
                    .company-holder {
                        .logo {
                            text-align: center;
                        }

                        .contacts {
                            .contact-line {
                                text-align: center;
                            }
                        }

                        .copyright {
                            text-align: center;
                        }
                    }
                }

                .socials {
                    width: 100%;
                    .socials-holder {
                        .paying-methods {
                            .heading {
                                text-align: center;
                            }

                            ul {
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .socials-links {
                            margin-bottom: 21px;
                            .heading {
                                text-align: center;
                            }

                            ul {
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .policy {
                            text-align: center;
                            a {
                                margin: 0 0 0 -2px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
