@media (max-width: $screen-sm) {
    // sm-view
    .wrapper {
    	min-width: 100%;
    	max-width: 100%;
    }

    .wrapper-inside {
        width: 90.625%;
    }

    /* Header
    -----------------------------------------------------------------------------*/
    .header {

    }

    /* Middle
    -----------------------------------------------------------------------------*/
    .content {

    }

    /* Footer
    -----------------------------------------------------------------------------*/
    .footer {
        min-width: 100%;
    }

    .page-heading {
        .page-heading-holder {
            h1, .h1 {
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 150%;
            }
        }
    }

    h1, .h1 {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 150%;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
